import React, { useState, useEffect } from "react";
import { Box, Button, Icon, LinearProgress } from "@mui/material";
import Modal from "../Modal";
import { success, gray } from "../../utils/colors";
import { connect } from "react-redux";

const ResultModal = props => {
  const { modalName, modalTitle, btnTxt, btnColor, btnDisabled, isModalOpen, toggle, handle, children, noBtn, shouldHandle } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleLoading = (modalName, isLoading) => {
    setIsLoading(isLoading);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await props.handle({ ...props, handleLoading });
      } catch (e) {
        console.error(e);
      }
    };
    if (isModalOpen && shouldHandle) {
      fetchData();
    }
  }, [isModalOpen]);

  const btnDisabledFromParent = btnDisabled || false;
  const disabled = !!(btnDisabledFromParent || isLoading);

  const content = (
    <div>
      <Box mt={2} mb={2}>
        {isLoading && <LinearProgress />}
      </Box>
      {children && <Box mb={2}>{children}</Box>}
      {!noBtn && (
        <Box mb={2}>
          <Button
            onClick={() => handle({ ...props, handleLoading })}
            disabled={disabled}
            variant="contained"
            size="small"
            style={{
              background: !disabled ? (btnColor ? btnColor : success) : gray,
              color: "#fff",
              width: "min-content",
              float: "right",
              cursor: "pointer"
            }}
            endIcon={<Icon>arrow_right</Icon>}
          >
            {btnTxt}
          </Button>
          <div style={{ clear: "both" }} />
        </Box>
      )}
    </div>
  );

  return isModalOpen ? (
    <Modal open={isModalOpen} toggle={() => toggle(modalName)} title={modalTitle} description="" closeIcon>
      {content}
    </Modal>
  ) : null;
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ResultModal);
