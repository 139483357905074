import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Grid } from "@mui/material";
import routesConfig from "../../../routes/routesConfig";
import { siteConfig } from "../../../config/mainConfig";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    //todo
  }

  render() {
    const { navigate } = this.props;
    return (
      <PageLayout title="Reports" {...this.props}>
        <Grid item xs={12} sm={12} md={12}>
          {/*{siteConfig === "boleron" && (*/}
          {/*  <span className="link" style={{ display: "block" }} onClick={() => navigate(routesConfig.bestDoctorsReport.route)}>*/}
          {/*    Best Doctors отчет*/}
          {/*  </span>*/}
          {/*)}*/}

          {/*}  <span className="link" style={{ display: "block" }} onClick={() => navigate(routesConfig.kfnReport.route)}>
            КФН отчет
          </span> */}
          <span className="link" style={{ display: "block" }} onClick={() => navigate(routesConfig.salesReport.route)}>
            Отчет Продажби
          </span>
          <span className="link" style={{ display: "block" }} onClick={() => navigate(routesConfig.kfnReport.route)}>
            Отчет КФН
          </span>
          <span className="link" style={{ display: "block" }} onClick={() => navigate(routesConfig.reportsTransactions.route)}>
            Отчет POS транзакции
          </span>
          {/*<span className="link" style={{ display: "block" }} onClick={() => navigate(routesConfig.endingPoliciesReport.route)}>*/}
          {/*  Отчет изтичащи полици*/}
          {/*</span>*/}
          {/*<span className="link" style={{ display: "block" }} onClick={() => navigate(routesConfig.endingPaymentsReport.route)}>*/}
          {/*  Отчет изтичащи вноски*/}
          {/*</span>*/}
          {/*  <span className="link" style={{ display: "block" }} onClick={() => navigate(routesConfig.reportsForPeriod.route)}>
            Полици и вноски за период
          </span>
          <span className="link" style={{ display: "block" }} onClick={() => navigate(`${routesConfig.commissionAndWeeklyReports.route}/commissions`)}>
            Отчети за комисионни
          </span> */}
          <span className="link" style={{ display: "block" }} onClick={() => navigate(`${routesConfig.commissionAndWeeklyReports.route}/weekly`)}>
            Седмични отчети
          </span>
          <span className="link" style={{ display: "block" }} onClick={() => navigate(`${routesConfig.commissionAndWeeklyReports.route}/monthly`)}>
            Месечни отчети
          </span>
          <span className="link" style={{ display: "block" }} onClick={() => navigate(`${routesConfig.commissionAndWeeklyReports.route}/commissions`)}>
            Обобщена месечна справка
          </span>
        </Grid>
      </PageLayout>
    );
  }
}
export default Reports;
