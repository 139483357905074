import React from "react";
import { Typography } from "@mui/material";
import StatusBadge from "../Policies/StatusBadge";
import moment from "moment/moment";

const PolicyInfo = props => {
  const { commissionPercentage, contractDate, policy } = props;
  const isActive = new Date(policy.validTo).getTime() > new Date().getTime();
  return (
    <div className="edit-policy-box">
      <Typography style={{ fontSize: "20px", textAlign: "center" }}>Полица </Typography>
      <div className="horizontally-centered">
        <StatusBadge isPending={policy.isPending} isActive={isActive} isCancelled={policy.isCancelled} />
      </div>
      <p className="paragraph">Полица: {policy.policyId || " - "}</p>
      <p className="paragraph">Продукт: {policy.policyData.productName}</p>
      <p className="paragraph">Застраховател: {policy.policyData.insurerName}</p>
      <p className="paragraph">Вноски: {policy.policyData.installments}</p>
      <p className="paragraph">Застраховащ: {policy.name}</p>
      <p className="paragraph">ЕГН: {policy.egn}</p>
      <p className="paragraph">Телефон: {policy.phone}</p>
      <p className="paragraph">Имейл: {policy.email}</p>
      <p className="paragraph">Премия: {policy.premium} лв.</p>
      <p className="paragraph">Данък: {policy.tax} лв.</p>
      <p className="paragraph">Общо: {policy.total} лв.</p>
      <p className="paragraph">От: {moment(policy.validFrom).locale("bg").format("DD.MM.YYYY")}</p>
      <p className="paragraph">До: {moment(policy.validTo).locale("bg").format("DD.MM.YYYY")}</p>
      {contractDate && <p className="paragraph">Дата на сключване: {moment(policy.contractDate).locale("bg").format("DD.MM.YYYY")}</p>}
      {commissionPercentage > -1 && <p className="paragraph">Комисионна в проценти: {commissionPercentage}% </p>}
    </div>
  );
};

export default PolicyInfo;
