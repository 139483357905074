import React from "react";
import { getLabelByBSOStatusAndType } from "../../../utils/helpers";

const CancelledDocuments = props => {
  const { cancelledDocuments = {} } = props;
  const { cancelledStickers, cancelledGreenCards } = cancelledDocuments;
  return (
    <>
      {!!cancelledStickers?.length && (
        <div className="edit-policy-cancelled-docs-box">
          <p className="red">Анулирани/преиздадени стикери</p>
          {cancelledStickers?.map(cancelledSticker => (
            <p key={`canceled-sticker-${cancelledSticker?._id}`}>
              <b>{cancelledSticker?.stickerNumber}</b>
              {`, ${cancelledSticker?.price?.toFixed(2)}лв. `}
              <span>{`(${getLabelByBSOStatusAndType(cancelledSticker?.status, cancelledSticker?.bsoType)})`}</span>
            </p>
          ))}
        </div>
      )}
      {!!cancelledGreenCards?.length && (
        <div className="edit-policy-cancelled-docs-box">
          <p className="red">Анулирани/преиздадени зелени карти</p>
          {cancelledGreenCards?.map(cancelledGreenCard => (
            <p key={`canceled-green-card-${cancelledGreenCard?._id}`}>
              <b>{cancelledGreenCard?.greenCardWithPrefix}</b>
              {`, ${cancelledGreenCard?.price?.toFixed(2)}лв. `}
              <span>{`(${getLabelByBSOStatusAndType(cancelledGreenCard?.status, cancelledGreenCard?.bsoType)})`}</span>
            </p>
          ))}
        </div>
      )}
    </>
  );
};

export default CancelledDocuments;
