// Based on SalesReport - src\components\pages\SalesReport\SalesReport.js
import * as React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Button, Grid, Icon, MenuItem, Select, TextField } from "@mui/material";
import moment from "moment";
import { error as errorAlert, success as successAlert } from "../../../utils/notifications";
import { sendReportsForPeriod } from "../../../services/PoliciesService";
import { success, gray } from "../../../utils/colors";

class ReportsForPeriod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      insurer: "all",
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD")
    };

    this.handleTxtChange = this.handleTxtChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleTxtChange(e) {
    this.setState({
      email: e.target.value
    });
  }
  handleSelectChange(e) {
    this.setState({
      insurer: e.target.value
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      const { email, fromDate, toDate, insurer } = this.state;
      const response = await sendReportsForPeriod({ email, fromDate, toDate, insurer });
      if (response.status === 200) {
        this.setState({
          email: ""
        });
        successAlert("sent successfully!");
      }
    } catch (e) {
      errorAlert(e.message);
    }
  }

  handleDate(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  render() {
    const { email, fromDate, toDate, insurer } = this.state;
    const btnDisabled = !fromDate || !toDate || !email;
    return (
      <PageLayout title="Полици и вноски за период" {...this.props}>
        <Grid item xs={6} sm={4} md={2}>
          <form style={{ marginLeft: "10px" }} onSubmit={this.handleSubmit}>
            <Box mb={2}>
              <Select
                style={{ width: "100%" }}
                SelectDisplayProps={{
                  style: { fontSize: 16 }
                }}
                labelId="demo-simple-select-placeholder-label-label"
                id="outlined-size-small"
                value={insurer}
                onChange={e => this.handleSelectChange(e, "insurer")}
                displayEmpty
              >
                <MenuItem value={"all"}>Всички</MenuItem>
                <MenuItem value={"allianz"}>Алианц</MenuItem>
                <MenuItem value={"armeec"}>Армеец</MenuItem>
                <MenuItem value={"bulins"}>Бул Инс</MenuItem>
                <MenuItem value={"bulstrad"}>Булстрад</MenuItem>
                <MenuItem value={"bulstradlife"}>Булстрад Живот</MenuItem>
                <MenuItem value={"dallbogg"}>Дал Бог</MenuItem>
                <MenuItem value={"dzi"}>ДЗИ</MenuItem>
                <MenuItem value={"euroins"}>Евроинс</MenuItem>
                <MenuItem value={"generali"}>Дженерали</MenuItem>
                <MenuItem value={"levins"}>Левинс</MenuItem>
                <MenuItem value={"ozk"}>ОЗК</MenuItem>
                <MenuItem value={"uniqa"}>Уника</MenuItem>
                <MenuItem value={"coface"}>Coface</MenuItem>
                <MenuItem value={"colonnade"}>Colonnade</MenuItem>
              </Select>
            </Box>
            <Box mb={2}>
              <TextField fullWidth label="email" id="outlined-size-small" variant="outlined" size="small" value={email} onChange={this.handleTxtChange} />
            </Box>
            <Box mb={2}>
              <TextField
                name="fromDate"
                fullWidth
                onChange={this.handleDate}
                id="datetime-local"
                label="Начална дата"
                type="date"
                value={fromDate}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                name="toDate"
                fullWidth
                onChange={this.handleDate}
                id="datetime-local"
                label="Крайна дата"
                type="date"
                value={toDate}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>
            <Box mb={2}>
              <Button
                disabled={btnDisabled}
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: !btnDisabled ? success : gray,
                  color: "#fff",
                  width: "min-content",
                  float: "right"
                }}
                endIcon={<Icon>arrow_right</Icon>}
              >
                Изпрати
              </Button>
              <div style={{ clear: "both" }} />
            </Box>
          </form>
        </Grid>
      </PageLayout>
    );
  }
}

export default ReportsForPeriod;
