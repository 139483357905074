import React from "react";
import { CircularProgress, Typography } from "@mui/material";

const Loading = () => (
  <div className="edit-policy-loading-wrapper">
    <Typography gutterBottom variant="body1" component="span">
      <p>Зареждане на полица ...</p>
    </Typography>
    <CircularProgress size={20} />
  </div>
);

export default Loading;
