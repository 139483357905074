import React from "react";
import { round } from "../../../utils/helpers";
import _get from "lodash.get";
import moment from "moment/moment";
import CancelledDocuments from "./CancelledDocuments";

const DuePaymentInfo = props => {
  const { payment, calculatedCommision } = props;

  return (
    <div className="edit-policy-box">
      <p style={{ textAlign: "center" }}>Вноска: {payment.paymentId}</p>
      <p className="paragraph">Сума: {round(_get(payment, "amount", 0))}</p>
      <p className="paragraph">Дата на плащане 1: {`${moment(payment.dueDate).format("DD.MM.YYYY")} г.`}</p>
      <p className="paragraph">Дата на падеж: {`${moment(payment.contractDueDate).format("DD.MM.YYYY")} г.`}</p>
      <p className="paragraph">Начин на плащане: {payment.isOffline ? "Наложен платеж" : "Карта"}</p>
      <p className="paragraph">Платена: {payment.toProcess ? "Не" : "Да"}</p>
      <CancelledDocuments cancelledDocuments={payment?.cancelledDocuments} />

      {!payment.toProcess && payment?.receiptLink && (
        <p className="paragraph">
          <a href={payment.receiptLink} target="_blank" rel="noopener noreferrer">
            Разписка
          </a>
        </p>
      )}
      <p className="paragraph">Комисионна в проценти: {`${calculatedCommision}%`}</p>
    </div>
  );
};

export default DuePaymentInfo;
