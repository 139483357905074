import React from "react";
import { txtFields } from "../../../../config/StickerForm.component.config";
import withValidation from "../../../HOC/withValidation";
import { cancelOrReissueGODocument } from "../../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../../utils/notifications";
import { Button, Grid } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

const CancelStickerForm = props => {
  const { onSuccess, id, installmentNumber, formData, payment, policy, status, btnTxt } = props;
  async function handleCancelSticker(e) {
    if (props?.handleValidation()) {
      try {
        const response = await cancelOrReissueGODocument(id, installmentNumber, "sticker", formData["sticker"], status);
        if (response?.status === 200) {
          successAlert("cancelled/reissued successfully!");
          onSuccess(response.data);
        }
      } catch (e) {
        errorAlert(e?.response?.data || e?.message);
      }
    }
  }
  return (
    <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
      <Grid item xs={8}>
        {props?.renderFields(props, txtFields)}
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          startIcon={<DeleteIcon />}
          type="submit"
          color={status === "cancelled" ? "error" : "warning"}
          onClick={handleCancelSticker}
          sx={{ mb: 1, mt: 1 }}
        >
          {btnTxt}
        </Button>
      </Grid>
    </Grid>
  );
};

export default withValidation(CancelStickerForm, { txtFields });
