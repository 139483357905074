import React, { useState, createElement, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";
import PoliciesTableNew from "../Policies/PoliciesTableNew";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import _get from "lodash.get";
import _find from "lodash.find";
import withMultipleModals from "../../HOC/withMultipleModals";
import withParams from "../../HOC/withParams";
import { rolesMatched } from "../../PrivateRoute/PrivateRoute";
import routesConfig from "../../../routes/routesConfig";
import qs from "qs";
import { capitalizeFirstLetter, getKeyByValue, timeout } from "../../../utils/helpers";
import * as AllActionModals from "../../ActionModals";
import InnerTabs from "../../InnerTabs";
import ActionsProvider from "../../ActionsProvider";
import { getOrCreateAnnex } from "../../../services/AnnexesService";

const Casco = props => {
  const variantLookup = {
    process: "Предстоящи",
    overdue: "Просрочени",
    informed: "Информирани",
    confirmed: "Платени",
    issued: "Издадени",
    paidElsewhere: "Платени другаде",
    rejected: "Отказани",
    cancelled: "Прекратени", //todo да се провери дали да остане
    partiallyPaid: "Отказано плащане"
  };

  const policiesConfig = {
    active: "Активни",
    expiring: "Изтичащи",
    renewed: "Подновени",
    cancelled: "Прекратени",
    informed: "Информирани",
    all: "Всички"
  };

  const subVariantsConfig = {
    queries: "offerSelectedTab",
    policies: "policiesSelectedTab",
    installments: "installmentsSelectedTab",
    expiring: ""
  };

  //todo този код може да отиде в withMultipleModals HOK-a
  const modals = {};
  props.data.forEach(el => {
    modals[el.id] = AllActionModals[capitalizeFirstLetter(el.id)];
  });
  const [selectedTab, setSelectedTab] = useState(sessionStorage.getItem("selectedTab") || "queries");
  const [offerSelectedTab, setOfferSelectedTab] = useState(sessionStorage.getItem("offerSelectedTab") || "query");
  const [policiesSelectedTab, setPoliciesSelectedTab] = useState(sessionStorage.getItem("policiesSelectedTab") || getKeyByValue(policiesConfig, "Активни"));

  const [installmentsSelectedTab, setInstallmentsSelectedTab] = useState(sessionStorage.getItem("installmentsSelectedTab") || getKeyByValue(variantLookup, "Предстоящи"));
  const [policy, setPolicy] = useState({});
  const [annex, setAnnex] = useState({});
  const [user] = useState(_get(props, "auth.user"));
  const searchQuery = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "query", "");
  const { roles } = user || {};
  const tabsState = { selectedTab, offerSelectedTab, installmentsSelectedTab, policiesSelectedTab };

  const handleOfferChange = (e, selected) => {
    setOfferSelectedTab(selected);
    sessionStorage.setItem("offerSelectedTab", selected);
  };

  const handleInstallmentsChange = (e, selected) => {
    setInstallmentsSelectedTab(selected);
    sessionStorage.setItem("installmentsSelectedTab", selected);
  };

  const handlePoliciesChange = (e, selected) => {
    setPoliciesSelectedTab(selected);
    sessionStorage.setItem("policiesSelectedTab", selected);
  };

  const handleAction = async (policy, option, refreshDataMethod, asyncData) => {
    setPolicy(policy);
    if (asyncData?.getAnnex) {
      const annex = _get(await getOrCreateAnnex({ requestId: policy?.requestId }), "data");
      setAnnex(annex);
    }
    if (option.hasModal) {
      props.open(`${option.id}Modal`);
      props.setRefreshMethod(refreshDataMethod);
      props.closeAllExceptOne(`${option.id}Modal`);
    } else if (!option.hasModal && option.id === "sendOffer") {
      props.navigate(`${routesConfig.sendOffer.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "editPolicy") {
      //todo да се провери дали policy._id го има при installments
      props.navigate(`${routesConfig.editPolicy.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "sendDocuments") {
      if (selectedTab === "installments") {
        if (policy.deliveryName) {
          return props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${policy.paymentId}/${policy.productId}/${policy.email}/${policy.deliveryName}`);
        } else {
          return props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${policy.paymentId}/${policy.productId}/${policy.email}/${policy.name}`);
        }
      } else {
        if (policy.policyData.deliveryName) {
          let goInstallmentNo = policy.policyData.goInstallmentNo;
          if (goInstallmentNo) {
            goInstallmentNo = "" + goInstallmentNo;
            props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${goInstallmentNo}/${policy.productId}/${policy.email}/${policy.policyData.deliveryName}`);
          } else {
            props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/1/${policy.productId}/${policy.email}/${policy.policyData.deliveryName}`);
          }
        } else {
          props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/1/${policy.productId}/${policy.email}/${policy.name}`);
        }
      }
    } else if (!option.hasModal && option.id === "shipment") {
      if (selectedTab === "installments") {
        props.navigate(`${routesConfig.shipment.route}/${policy._id}`, { state: JSON.stringify(policy) }); // state-a в ReactRouter се очаква да е serializable, ako ne e праща null
      } else {
        props.navigate(`${routesConfig.shipment.route}/${policy._id}`);
      }
    }
  };

  const innerTabsOffers = (
    <InnerTabs
      selected={offerSelectedTab}
      handleChange={handleOfferChange}
      options={[
        { label: "Обработват се", value: "query" },
        { label: "Изпратени", value: "offers" },
        { label: "Платени", value: "confirmed" },
        { label: "Отказани", value: "rejected" },
        { label: "Издадени", value: "issued" }
      ]}
    />
  );
  const innerTabsPolicies = (
    <InnerTabs
      selected={policiesSelectedTab}
      handleChange={handlePoliciesChange}
      options={[
        { label: "Активни", value: "active" },
        { label: "Изтичащи", value: "expiring" },
        { label: "Информирани", value: "informed" },
        { label: "Подновени", value: "renewed" },
        { label: "Прекратени", value: "cancelled" },
        { label: "Всички", value: "all" }
      ]}
    />
  );
  const innerTabsInstallments = (
    <InnerTabs
      selected={installmentsSelectedTab}
      handleChange={handleInstallmentsChange}
      options={[
        { label: "Предстоящи", value: getKeyByValue(variantLookup, "Предстоящи") },
        { label: "Просрочени", value: getKeyByValue(variantLookup, "Просрочени") },
        { label: "Информирани", value: getKeyByValue(variantLookup, "Информирани") },
        { label: "Платени", value: getKeyByValue(variantLookup, "Платени") },
        { label: "Издадени", value: getKeyByValue(variantLookup, "Издадени") },
        { label: "Платени другаде", value: getKeyByValue(variantLookup, "Платени другаде") },
        { label: "Отказани", value: getKeyByValue(variantLookup, "Отказани") },
        { label: "Прекратени", value: getKeyByValue(variantLookup, "Прекратени") },
        { label: "Всички", value: "all" }
      ]}
    />
  );

  useEffect(() => {}, [props.dataStatuses]);
  return (
    <PageLayout title="Каско" hasGrayBg {...props}>
      <ActionsProvider>
        {Object.keys(modals).map(k => {
          let modal = modals[k];
          return createElement(modal, {
            key: k,
            [`is${capitalizeFirstLetter(k)}Open`]: _get(_find(props.dataStatuses, { id: k }), "opened"),
            toggle: props.toggle,
            close: props.close,
            refreshTable: props?.refreshData,
            policy: ["refundModal", "incorrectPayersModal"].includes(k) ? policy : undefined,
            //todo тук могат да отпаднат policyDBId и paymentId като се преработи PayInstallmentModal модала
            objWithEgnAndRequestId: !["refundModal", "incorrectPayersModal"].includes(k)
              ? { ...policy, policyDBId: policy["_id"], paymentId: selectedTab === "installments" ? _get(policy, "paymentId")?.toString() : "1" }
              : undefined,
            variant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "changeStatusModal", "refundModal"].includes(k) ? selectedTab : undefined,
            subVariant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "changeStatusModal", "refundModal"].includes(k)
              ? tabsState[subVariantsConfig[selectedTab]]
              : undefined,
            annex: ["addAnnexWizardModal"].includes(k) ? annex : undefined
          });
        })}
        {rolesMatched(["admin"], roles) ? (
          <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: 10 }}>
            <Fab
              onClick={() => {
                props.navigate(routesConfig.offlinePolicy.route);
              }}
              color="primary"
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </div>
        ) : null}

        {selectedTab === "queries" && (
          <>
            {offerSelectedTab === "query" && (
              <PoliciesTableNew
                {...props}
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["pending"]}
                offerDefaultFilter={["offline"]}
                // offerstatusLookup={{
                //   offerstatusquery: "Заявка",
                //   offerstatusconfirmed: "Платена Оферта",
                //   offerstatusclientoffered: "Изпратена Оферта",
                //   offerstatusrejected: "Отказана"
                // }}
                offerstatusDefaultFilter={["offerstatusquery"]}
                // offerstatusDefaultFilter={["offerstatusquery", "statusquerynone"]} // todo ако искаме да показваме такива, които са pending, но със тотал различен он 0
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "offers" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["pending"]}
                offerDefaultFilter={["offline"]}
                offerstatusDefaultFilter={["offerstatusclientoffered"]}
                insurerIdLookup={{
                  armeec: "Армеец",
                  euroins: "Евроинс",
                  levins: "Лев Инс",
                  generali: "Дженерали",
                  dzi: "ДЗИ",
                  bulstrad: "Булстрад",
                  bulins: "Бул Инс",
                  allianz: "Алианц",
                  dallbogg: "ДаллБогг",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "confirmed" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["pending"]}
                offerDefaultFilter={[]}
                offerstatusDefaultFilter={["offerstatusconfirmed"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "rejected" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusDefaultFilter={["cancelled"]}
                offerDefaultFilter={["offline"]}
                offerstatusDefaultFilter={["offerstatusrejected"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "issued" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusDefaultFilter={["active"]}
                offerDefaultFilter={["offline"]}
                offerstatusDefaultFilter={[]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={["productId", "status", "offer", "offerStatus", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
          </>
        )}
        {selectedTab === "policies" && (
          <>
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Активни") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusDefaultFilter={["active", "renewal"]}
                // offerLookup={{ offline: "оферта", online: "онлайн" }}
                offerDefaultFilter={[]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Изтичащи") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusDefaultFilter={["expiring"]}
                offerDefaultFilter={[]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Информирани") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusDefaultFilter={["informed"]}
                offerDefaultFilter={[]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Подновени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusDefaultFilter={["renewed"]}
                offerDefaultFilter={[]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusDefaultFilter={["cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Всички") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC"]}
                statusDefaultFilter={["active", "pending", "expiring", "cancelled", "renewed", "informed", "renewal"]}
                offerDefaultFilter={[]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
          </>
        )}
        {selectedTab === "installments" && (
          <>
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Предстоящи") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={["online", "offline"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["process"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Платени другаде") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={["online", "offline"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["paidElsewhere"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Информирани") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={["online", "offline"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["informed"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Платени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={["online", "offline"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["confirmed"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Отказани") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["rejected"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={["online", "offline"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["cancelled", "partiallyPaid"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Издадени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={["online", "offline"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["issued"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-30"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Просрочени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={["online", "offline"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["overdue"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-30"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === "all" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  CSC: "Каско"
                }}
                productDefaultFilter={["CSC"]}
                primacy="duepaymentPrimacy"
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={["online", "offline"]}
                insurerIdLookup={{
                  allianz: "Алианц",
                  armeec: "Армеец",
                  bulins: "Бул Инс",
                  bulstrad: "Булстрад",
                  dallbogg: "ДаллБогг",
                  dzi: "ДЗИ",
                  euroins: "Евроинс",
                  generali: "Дженерали",
                  levins: "Лев Инс",
                  ozk: "ОЗК",
                  uniqa: "Уника"
                }}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
          </>
        )}
      </ActionsProvider>
    </PageLayout>
  );
};

export default withMultipleModals(withParams(Casco), [
  { id: "refundModal" },
  { id: "cancelPolicyModal" },
  { id: "changeStatusModal" },
  { id: "checkGOStatusModal" },
  { id: "changeStatusPolicyModal" },
  { id: "createPolicyModal" },
  { id: "incorrectPayersModal" },
  { id: "riskyUserProfileModal" },
  { id: "payInstallmentModal" },
  { id: "updateDateOfDuePaymentModal" },
  { id: "issuePolicyWizardModal" },
  { id: "addAnnexWizardModal" }
]);
