import React from "react";
import _get from "lodash.get";
import { createGOPolicy } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { TextField } from "@mui/material";
import moment from "moment";
import { danger, primary } from "../../utils/colors";

// todo за момента не се използва никъде
class CreateGOPolicyConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    const dateFrom = _get(props, "objWithEgnAndRequestId.policyData.validFrom");
    const dateTo = _get(props, "objWithEgnAndRequestId.policyData.validTo");
    this.state = {
      dateFrom: dateFrom ? moment(dateFrom).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"),
      dateTo: dateTo ? moment(dateTo).format("DD-MM-YYYY") : moment().add(1, "years").format("DD-MM-YYYY"),
      sticker: "",
      greenCard: "",
      prefix: ""
    };
  }
  handleDateChange = e => {
    const value = e.target.value.trim() ? e.target.value : "";
    const isValid = moment(value, "DD-MM-YYYY", true).isValid();
    this.setState({
      dateFrom: value,
      dateTo: isValid ? moment(value, "DD-MM-YYYY").add(1, "years").format("DD-MM-YYYY") : ""
    });
  };
  handleStickerChange = e => {
    const value = e.target.value.trim();
    this.setState({
      sticker: value
    });
  };
  handleGreenCardChange = e => {
    const value = e.target.value.trim();
    this.setState({
      greenCard: value
    });
  };
  handlePrefixChange = e => {
    const value = e.target.value.trim();
    this.setState({
      prefix: value
    });
  };
  dateFromIsValid = () => {
    const date = moment(this.state.dateFrom, "DD-MM-YYYY", true);
    return date.isValid();
  };
  stickerIsValid = () => {
    const sticker = (this.state.sticker.length === 8 || this.state.sticker.length === 9) && !/\D/.test(this.state.sticker);
    return sticker;
  };
  greenCardIsValid = () => {
    const greenCard = this.state.greenCard.length === 6 && !/\D/.test(this.state.greenCard);
    return greenCard;
  };
  prefixIsValid = () => {
    const prefix = this.state.prefix.length === 2 && /\D/.test(this.state.prefix) && this.state.prefix === this.state.prefix.toUpperCase();
    return prefix;
  };
  render() {
    const { modalName, isCreateGOPolicyModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange } = this.props;
    const modalTitle = "Въведи начална дата";
    const btnTxt = "Създай";
    return (
      <ConfirmationModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={primary}
        btnTxt={btnTxt}
        btnDisabled={(!this.dateFromIsValid(), !this.stickerIsValid(), !this.greenCardIsValid(), !this.prefixIsValid())}
        isModalOpen={isCreateGOPolicyModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={{
          ...objWithEgnAndRequestId,
          dateFrom: `${this.state.dateFrom} 12:00`,
          dateTo: `${this.state.dateTo} 12:00`,
          sticker: `${this.state.sticker}`,
          greenCard: `${this.state.greenCard}`,
          prefix: this.state.prefix
        }}
        egn={egn}
        handle={handle}
        showEgnCheck={showEgnCheck}
        egnValidationError={egnValidationError}
        onChange={onChange}
      >
        <TextField
          fullWidth
          label="Въведи датата"
          placeholder="24-01-2020"
          id="outlined-size-small-date-from"
          variant="outlined"
          size="small"
          value={this.state.dateFrom}
          onChange={this.handleDateChange}
        />
        <br />
        <br />
        <TextField
          fullWidth
          label="Въведи стикер"
          placeholder="Ex: 00012678"
          id="outlined-size-small-date-from"
          variant="outlined"
          size="small"
          value={this.state.sticker}
          onChange={this.handleStickerChange}
        />
        <br />
        <br />
        <TextField
          fullWidth
          label="Въведи номер на Зелена карта"
          placeholder="Ex. 001278"
          id="outlined-size-small-date-from"
          variant="outlined"
          size="small"
          value={this.state.greenCard}
          onChange={this.handleGreenCardChange}
        />
        <br />
        <br />
        <TextField
          fullWidth
          label="Въведи префикс на Зелена карта"
          placeholder="Ex. BG/XY..."
          id="outlined-size-small-date-from"
          variant="outlined"
          size="small"
          value={this.state.prefix}
          onChange={this.handlePrefixChange}
        />
        {!this.dateFromIsValid() && <p style={{ color: danger }}>Невалидна дата(формат ден-месец-година) </p>}
        {!this.stickerIsValid() && <p style={{ color: danger }}>Невалиден Стикер - Трябва да е 8 или 9 цифри. </p>}
        {!this.greenCardIsValid() && <p style={{ color: danger }}>Невалиден номер на Зелена карта - Трябва да е 6 цифри. </p>}
        {!this.prefixIsValid() && <p style={{ color: danger }}>Невалиден Префикс на Зелена карта- Трябва да е 2 ГЛАВНИ букви. </p>}
      </ConfirmationModal>
    );
  }
}
const generatePayloadRequest = props => {
  const requestPayload = {
    policyId: _get(props, "objWithEgnAndRequestId._id"),
    insurerName: _get(props, "objWithEgnAndRequestId.insurerId"),
    dateFrom: _get(props, "objWithEgnAndRequestId.dateFrom"),
    dateTo: _get(props, "objWithEgnAndRequestId.dateTo"),
    sticker: _get(props, "objWithEgnAndRequestId.sticker"),
    greenCard: _get(props, "objWithEgnAndRequestId.greenCard"),
    prefix: _get(props, "objWithEgnAndRequestId.prefix")
  };
  return requestPayload;
};

export default withConfirmationModal(CreateGOPolicyConfirmationModal, {
  generatePayloadRequest,
  apiRequest: createGOPolicy,
  modalName: "createGOPolicyModal",
  successMessage: "Успешна промяна!"
});
