import React, { useState, createElement, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";
import PoliciesTableNew from "../Policies/PoliciesTableNew";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import _get from "lodash.get";
import _find from "lodash.find";
import withMultipleModals from "../../HOC/withMultipleModals";
import withParams from "../../HOC/withParams";
import { rolesMatched } from "../../PrivateRoute/PrivateRoute";
import routesConfig from "../../../routes/routesConfig";
import qs from "qs";
import { capitalizeFirstLetter, getKeyByValue } from "../../../utils/helpers";
import * as AllActionModals from "../../ActionModals";
import InnerTabs from "../../InnerTabs";
import { useLocation } from "react-router-dom";
import ActionsProvider from "../../ActionsProvider";

const CascoPlusGo = props => {
  const insurerIdLookup = {
    allianz: "Алианц",
    armeec: "Армеец",
    bulins: "Бул Инс",
    bulstrad: "Булстрад",
    dallbogg: "ДаллБогг",
    dzi: "ДЗИ",
    euroins: "Евроинс",
    generali: "Дженерали",
    levins: "Лев Инс",
    ozk: "ОЗК",
    uniqa: "Уника"
  };
  const variantLookup = {
    process: "Предстоящи",
    overdue: "Просрочени",
    informed: "Информирани",
    confirmed: "Платени",
    issued: "Издадени",
    paidElsewhere: "Платени другаде",
    rejected: "Отказани",
    cancelled: "Прекратени" //todo да се провери дали да остане
  };

  const policiesConfig = {
    active: "Активни",
    expiring: "Изтичащи",
    renewed: "Подновени",
    cancelled: "Прекратени",
    informed: "Информирани",
    all: "Всички"
  };

  const subVariantsConfig = {
    queries: "",
    offers: "offerSelectedTab",
    policies: "policiesSelectedTab"
  };

  //todo този код може да отиде в withMultipleModals HOK-a
  const modals = {};
  props.data.forEach(el => {
    modals[el.id] = AllActionModals[capitalizeFirstLetter(el.id)];
  });
  const [selectedTab, setSelectedTab] = useState(sessionStorage.getItem("selectedTab") || "queries");
  const [offerSelectedTab, setOfferSelectedTab] = useState(sessionStorage.getItem("offerSelectedTab") || "query");
  const [policiesSelectedTab, setPoliciesSelectedTab] = useState(sessionStorage.getItem("policiesSelectedTab") || getKeyByValue(policiesConfig, "Активни"));

  const [policy, setPolicy] = useState({});
  const [user] = useState(_get(props, "auth.user"));
  const searchQuery = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "query", "");
  const { roles } = user || {};
  const tabsState = { selectedTab, offerSelectedTab, policiesSelectedTab };

  const handleOfferChange = (e, selected) => {
    setOfferSelectedTab(selected);
    sessionStorage.setItem("offerSelectedTab", selected);
  };

  const handlePoliciesChange = (e, selected) => {
    setPoliciesSelectedTab(selected);
    sessionStorage.setItem("policiesSelectedTab", selected);
  };

  const handleAction = (policy, option, refreshDataMethod) => {
    setPolicy(policy);
    if (option.hasModal) {
      props.open(`${option.id}Modal`);
      props.setRefreshMethod(refreshDataMethod);
      props.closeAllExceptOne(`${option.id}Modal`);
    } else if (!option.hasModal && option.id === "sendOffer") {
      props.navigate(`${routesConfig.sendOfferCascoPlusGO.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "editPolicy") {
      //todo да се провери дали policy._id го има при installments
      props.navigate(`${routesConfig.editPolicy.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "sendDocuments") {
      //todo в момента sendDocuments action-a е само за таб полици
      let goPolicy, cscPolicy;
      if (policy?.productId === "CSC") {
        goPolicy = policy?.goPolicy;
        cscPolicy = policy;
      } else if (policy?.productId === "GO") {
        goPolicy = policy;
        cscPolicy = policy?.cscPolicy;
      }
      if (policy?.policyData?.deliveryName) {
        props.navigate(`${routesConfig.sendDocumentsCascoPlusGO.route}/${cscPolicy._id}/${goPolicy?._id || "-"}/${policy.policyData.deliveryName}/${policy.email}`);
      } else {
        props.navigate(`${routesConfig.sendDocumentsCascoPlusGO.route}/${cscPolicy._id}/${goPolicy?._id || "-"}/${policy.name}/${policy.email}`);
      }
    }
  };

  const innerTabsOffers = (
    <InnerTabs
      selected={offerSelectedTab}
      handleChange={handleOfferChange}
      options={[
        { label: "Обработват се", value: "query" },
        { label: "Изпратени", value: "offers" },
        { label: "Платени", value: "confirmed" },
        { label: "Отказани", value: "rejected" }
        // {label: "Издадени", value: "issued"}
      ]}
    />
  );

  const innerTabsPolicies = (
    <InnerTabs
      selected={policiesSelectedTab}
      handleChange={handlePoliciesChange}
      options={[
        { label: "Активни", value: "active" },
        // { label: "Изтичащи", value: "expiring" },
        // { label: "Информирани", value: "informed" },
        // { label: "Подновени", value: "renewed" },
        { label: "Прекратени", value: "cancelled" },
        { label: "Всички", value: "all" }
      ]}
    />
  );

  useEffect(() => {}, [props.dataStatuses]);

  const location = useLocation();
  const currentRoute = _get(location, "pathname", "");
  const currentProduct = (currentRoute?.replace(/\//g, "") || "ALL").toUpperCase();
  return (
    <PageLayout title="Каско + Гражданска" hasGrayBg {...props}>
      <ActionsProvider>
        {Object.keys(modals).map(k => {
          let modal = modals[k];
          return createElement(modal, {
            key: k,
            [`is${capitalizeFirstLetter(k)}Open`]: _get(_find(props.dataStatuses, { id: k }), "opened"),
            toggle: props.toggle,
            close: props.close,
            refreshTable: props?.refreshData,
            policy: ["refundModal", "incorrectPayersModal"].includes(k) ? policy : undefined,
            //todo тук могат да отпаднат policyDBId и paymentId като се преработи PayInstallmentModal модала
            objWithEgnAndRequestId: !["refundModal", "incorrectPayersModal"].includes(k)
              ? {
                  ...policy,
                  policyDBId: policy["_id"],
                  paymentId: selectedTab === "installments" ? _get(policy, "paymentId")?.toString() : "1"
                }
              : undefined,
            variant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "changeStatusModal", "refundModal"].includes(k) ? selectedTab : undefined,
            subVariant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "changeStatusModal", "refundModal"].includes(k)
              ? tabsState[subVariantsConfig[selectedTab]]
              : undefined,
            currentProduct: ["issuePolicyWizardModal", "changeStatusPolicyModal", "refundModal"].includes(k) ? currentProduct : undefined
          });
        })}
        {rolesMatched(["admin"], roles) ? (
          <div style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: 10 }}>
            <Fab
              onClick={() => {
                props.navigate(routesConfig.offlinePolicy.route);
              }}
              color="primary"
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </div>
        ) : null}

        {selectedTab === "queries" && (
          <PoliciesTableNew
            {...props}
            title=""
            variant={selectedTab}
            productLookup={{
              ["CSC+GO"]: "Каско + Гражданска"
            }}
            primacy="policyPrimacy"
            productDefaultFilter={["CSC+GO"]}
            statuscombinedDefaultFilter={["pending"]}
            insurerIdLookup={insurerIdLookup}
            insurerIdDefaultFilter={[]}
            handleAction={handleAction}
            searchQuery={searchQuery}
            fromDays="-330"
            toDays="999"
            listOfProps={["productId", "statusCombined", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === "offers" && (
          <>
            {offerSelectedTab === "query" && (
              <PoliciesTableNew
                {...props}
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  ["CSC+GO"]: "Каско + Гражданска"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC+GO"]}
                statuscombinedDefaultFilter={["pending-offer"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={[
                  "productId",
                  "statusCombined",
                  "offer",
                  "offerStatus",
                  "insurerId",
                  "name",
                  "offerDate",
                  "period",
                  "sumTotal",
                  "numberOfInstallments",
                  "depositedTotal"
                ]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "offers" && (
              <PoliciesTableNew
                {...props}
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  ["CSC+GO"]: "Каско + Гражданска"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC+GO"]}
                statuscombinedDefaultFilter={["just-pending", "other"]}
                offerstatuscombinedDefaultFilter={["offerstatusclientoffered"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={[
                  "productId",
                  "statusCombined",
                  "offerStatusCombined",
                  "offer",
                  "offerStatus",
                  "insurerId",
                  "name",
                  "offerDate",
                  "period",
                  "sumTotal",
                  "numberOfInstallments",
                  "depositedTotal"
                ]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "confirmed" && (
              <PoliciesTableNew
                {...props}
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  ["CSC+GO"]: "Каско + Гражданска"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC+GO"]}
                statuscombinedDefaultFilter={["pending", "just-pending", "other"]}
                offerstatuscombinedDefaultFilter={["offerstatusconfirmed"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={[
                  "productId",
                  "statusCombined",
                  "offerStatusCombined",
                  "offer",
                  "offerStatus",
                  "insurerId",
                  "name",
                  "offerDate",
                  "period",
                  "sumTotal",
                  "numberOfInstallments",
                  "depositedTotal"
                ]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
            {offerSelectedTab === "rejected" && (
              <PoliciesTableNew
                {...props}
                title=""
                variant={selectedTab}
                subVariant={offerSelectedTab}
                productLookup={{
                  ["CSC+GO"]: "Каско + Гражданска"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC+GO"]}
                offerstatuscombinedDefaultFilter={["offerstatusrejected"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-330"
                toDays="999"
                listOfProps={[
                  "productId",
                  "offerStatusCombined",
                  "offer",
                  "offerStatus",
                  "insurerId",
                  "name",
                  "offerDate",
                  "period",
                  "sumTotal",
                  "numberOfInstallments",
                  "depositedTotal"
                ]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsOffers}
              />
            )}
          </>
        )}

        {selectedTab === "policies" && (
          <>
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Активни") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  ["CSC+GO"]: "Каско + Гражданска"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC+GO"]}
                statuscombinedDefaultFilter={["active"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "statusCombined", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  ["CSC+GO"]: "Каско + Гражданска"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC+GO"]}
                statuscombinedDefaultFilter={["cancelled", "partially-cancelled"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "statusCombined", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Всички") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  ["CSC+GO"]: "Каско + Гражданска"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["CSC+GO"]}
                statusDefaultFilter={["active", "pending", "expired", "cancelled", "renewed", "informed"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-1115"
                toDays="3000"
                listOfProps={["productId", "statusCombined", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
          </>
        )}
      </ActionsProvider>
    </PageLayout>
  );
};

export default withMultipleModals(withParams(CascoPlusGo), [
  { id: "refundModal" },
  { id: "cancelPolicyModal" },
  { id: "changeStatusModal" },
  { id: "checkGOStatusModal" },
  { id: "changeStatusPolicyModal" },
  { id: "createGOPolicyModal" },
  { id: "createPolicyModal" },
  { id: "incorrectPayersModal" },
  { id: "riskyUserProfileModal" },
  { id: "payInstallmentModal" },
  { id: "updateDateOfDuePaymentModal" },
  { id: "issuePolicyWizardModal" }
]);
