import React, { useState } from "react";
import Modal from "../Modal";
import { Box, Button, Grid, InputAdornment, InputLabel, TextField } from "@mui/material";
import { fields } from "../../config/SendForEvrotrustSigning.modal.config";
import _get from "lodash.get";
import _find from "lodash.find";
import { gray, grayBorder, grayLight } from "../../utils/colors";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectSendForSigningUploadedFiles, setSendForSigningUploadedFiles } from "../../store/reducers/form";
import withValidation from "../HOC/withValidation";
import WizardButton from "../Wizard/common/WizardButton";
import { sendFilesForSigningEvrotrust, uploadFile } from "../../services/PoliciesService";
import mergeByKey from "array-merge-by-key";
import { error as errorAlert, success as successAlert, warning as warningAlert } from "../../utils/notifications";

const modalName = "sendForEvrotrustSigningModal";

const SendForEvrotrustSigningConfirmationModal = props => {
  const { isSendForEvrotrustSigningModalOpen, toggle, objWithEgnAndRequestId: policy, close, variant } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const uploadedFiles = _get(useSelector(selectSendForSigningUploadedFiles), `[${policy._id}]`, []);
  const hasFiveUploadedFiles = uploadedFiles.find(x => x.has5)?.has5;
  const showSendBtn = uploadedFiles.filter(f => f.uploaded)?.length > 0 || hasFiveUploadedFiles;
  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      let { _id: policyDBId } = policy;

      let uploadCalls = [];
      let keyArr = [];
      for (const [key, value] of Object.entries(props.formData)) {
        if (value && !_get(_find(uploadedFiles, { key: key }), "uploaded")) {
          keyArr.push(key);
          const data = {
            file: value,
            policy: { _id: policyDBId },
            typeDocument: "otherFileSigned",
            isHidden: false
          };
          uploadCalls.push({ method: uploadFile, data });
        }
      }
      if (uploadCalls?.length > 0) {
        setLoading(true);
        const results = await Promise.all([...uploadCalls.map(call => _get(call, "method")(_get(call, "data")))].map(p => p.catch(e => e)));
        const validResults = results.filter(result => !(result instanceof Error));
        const forSigningUploadedFiles = [];

        results.forEach((r, index) => {
          if (!(r instanceof Error)) {
            // successfull
            forSigningUploadedFiles.push({
              key: keyArr[index],
              name: _get(props.formData, `${keyArr[index]}.name`, ""),
              uploaded: true,
              message: "файлът е качен!"
            });
          } else {
            //not sucessfull
            forSigningUploadedFiles.push({
              key: keyArr[index],
              name: _get(props.formData, `${keyArr[index]}.name`, ""),
              uploaded: false,
              message: r?.message
            });
          }
        });
        const allFiles = mergeByKey("key", uploadedFiles, forSigningUploadedFiles);
        dispatch(setSendForSigningUploadedFiles({ [policyDBId]: allFiles }));
        setLoading(false);

        if (Object.keys(props.formData)?.length === allFiles.filter(f => f.uploaded)?.length) {
          dispatch(setSendForSigningUploadedFiles({ [policyDBId]: [{ has5: allFiles.map(f => f?.name).join(", ") }] }));
        }
        if (validResults.length === uploadCalls.length) {
          successAlert("Всички избрани файлове са качени успешно!");
        } else {
          let outerErrors = {};
          forSigningUploadedFiles.forEach(({ key, message, uploaded }) => {
            if (!uploaded) {
              const error = { [key]: message };
              outerErrors = { ...outerErrors, ...error };
            }
          });
          props?.outerErrorsSet(outerErrors);
        }
      } else {
        warningAlert("Няма нищо за качване!");
      }
    }
  };

  const handleSendForSigning = async () => {
    try {
      let { _id: policyDBId } = policy;
      setLoading(true);
      await sendFilesForSigningEvrotrust({ policyOid: policy?._id });
      successAlert("Изпратихте файловете успешно!");
      dispatch(setSendForSigningUploadedFiles({ [policyDBId]: undefined }));
      setLoading(false);
      close(modalName);
    } catch (e) {
      setLoading(false);
      if (e?.response?.data) errorAlert("Error: " + e.response.data);
      else if (e?.message) errorAlert("Error: " + e.message);
      else errorAlert("Error occurred!");
    }
  };
  return (
    <Modal open={isSendForEvrotrustSigningModalOpen} toggle={() => toggle(modalName)} description="" closeIcon width={700} title="Изпрати за подпис в Евротръст">
      {isSendForEvrotrustSigningModalOpen && (
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box mb={5} display="flex" justifyContent="center">
              <span className="size-5">Клиент: {policy?.name}</span>
            </Box>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <div style={{ marginTop: 10, width: "60%" }}>
              {fields.map(f => (
                <div key={f.name}>
                  <InputLabel
                    sx={{
                      fontSize: ".7rem",
                      marginLeft: "15px"
                    }}
                  >
                    {f.label}
                  </InputLabel>
                  <TextField
                    label=""
                    id="outlined-start-adornment"
                    readOnly
                    style={{ width: "100%", marginBottom: "16px" }}
                    value={_get(props, `formData[${f.name}].name`, "")}
                    placeholder=""
                    disabled={_get(_find(uploadedFiles, { key: f.name }), "uploaded", false)}
                    inputProps={{
                      style: {
                        padding: 9,
                        color: gray
                      }
                    }}
                    FormHelperTextProps={{
                      className: _get(_find(uploadedFiles, { key: f.name }), "uploaded", false) ? "has-text-success" : "has-text-danger"
                    }}
                    helperText={props?.errors[f.name]}
                    error={!!props?.errors[f.name]}
                    InputProps={{
                      sx: {
                        padding: 0
                      },
                      endAdornment: (
                        <InputAdornment sx={{ height: "100%" }} position="end">
                          <Button
                            variant="contained"
                            color="inherit"
                            component="label"
                            disabled={_get(_find(uploadedFiles, { key: f.name }), "uploaded", false)}
                            sx={{
                              ml: 1,
                              py: "8px",
                              backgroundColor: grayLight,
                              boxShadow: "none",
                              borderLeft: `1px solid ${grayBorder}`
                            }}
                          >
                            Прикачи
                            <input type="file" hidden name={f.name} onChange={props?.handleFileChange} />
                          </Button>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              ))}
              {hasFiveUploadedFiles && (
                <p>
                  <span className="has-text-success">5 качени файла:</span> {hasFiveUploadedFiles}
                </p>
              )}
              <WizardButton disabled={loading} btnText="Качи" handleSubmit={handleSubmit} loading={loading} />
              {showSendBtn && <WizardButton disabled={loading} btnText="Изпрати" handleSubmit={handleSendForSigning} loading={loading} />}
            </div>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
};
function mapStateToProps(state) {
  return {
    form: state.form
  };
}
export default connect(mapStateToProps)(withValidation(SendForEvrotrustSigningConfirmationModal, { txtFields: fields, modalName }));
