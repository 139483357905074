import React, { useState } from "react";
import Modal from "../Modal";
import Wizard from "../Wizard";
import { getStepsForAddAnnexWizardModal } from "../DetailPanel/Annex/config";

const AddAnnexWizardModal = props => {
  const [title, setTitle] = useState("");
  const [width, setWidth] = useState(undefined);
  const { isAddAnnexWizardModalOpen, toggle, objWithEgnAndRequestId, close, variant, currentProduct, annex } = props;
  const modalName = "addAnnexWizardModal";
  const steps = getStepsForAddAnnexWizardModal(props);

  return (
    <Modal open={isAddAnnexWizardModalOpen} toggle={() => toggle(modalName)} description="" closeIcon noPadding width={width}>
      <Wizard
        isModalOpen={isAddAnnexWizardModalOpen}
        policy={objWithEgnAndRequestId}
        annex={annex}
        steps={steps}
        close={() => close(modalName)}
        setTitle={setTitle}
        setWidth={setWidth}
        title={title}
        variant={variant}
        currentProduct={currentProduct}
      />
    </Modal>
  );
};

export default AddAnnexWizardModal;
