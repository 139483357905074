import React from "react";
import { Chip, Typography } from "@mui/material";
import { warning, danger, success, gray } from "../../../utils/colors";

const getStatusColor = status => {
  switch (status) {
    case "active":
    case "renewal":
      return success;
    case "renewed":
    case "pending":
      return warning;
    case "cancelled":
    case "rejected":
    case "paidElsewhere":
      return danger;
    case "expired":
    case "expiring":
    default:
      return gray;
  }
};

const getBgTranslation = status => {
  switch (status) {
    case "active":
      return "Активна";
    case "pending":
      return "Обработва се";
    case "cancelled":
      return "Прекратена";
    case "expired":
      return "Изтекла";
    case "renewed":
      return "Подновена";
    case "renewal":
      return "Подновена";
    case "paidElsewhere":
      return "Платена друг.";
    default:
      return "";
  }
};

const getStatusName = props => {
  const { isPending, isActive, isCancelled, isRenewal, isPaidElsewhere, isRenewed } = props;
  if (isPending) return "pending";
  if (isCancelled) return "cancelled";
  if (isPaidElsewhere) return "paidElsewhere";
  if (isActive) return "active";
  if (isRenewal) return "renewal";
  if (isRenewed) return "renewed";
  return "expired";
};

const StatusBadge = props => {
  const style = {
    float: "right",
    color: "white",
    background: getStatusColor(getStatusName(props))
  };
  return (
    <div>
      <Chip size="small" label={<Typography style={{ fontSize: "0.7rem" }}>{getBgTranslation(getStatusName(props))}</Typography>} style={{ ...style, float: "none" }} />
    </div>
  );
};

export default StatusBadge;
