import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { Typography, Box, Button } from "@mui/material";
import Modal from "../../../Modal";
import CancelStickerForm from "./CancelStickerForm";
import NewStickerForm from "./NewStickerForm";
import CancelGreenCardForm from "./CancelGreenCardForm";
import NewGreenCardForm from "./NewGreenCardForm";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const CancelPolicyStickerModal = ({ open, onClose, toggle, onSuccess, id, installmentNumber, payment, policy, selected }) => {
  const [selectedTab, setSelectedTab] = useState(selected);

  useEffect(() => {
    setSelectedTab(selected);
  }, [selected]);

  const handleRadioChange = e => {
    setSelectedTab(e?.target?.value);
  };

  const title = (p => {
    if (p === "cancelled") return "Анулирай";
    if (p === "reissued") return "Преиздай";
    if (p === "update") return "Актуализирай";
    return "";
  })(selectedTab);

  const content = (
    <>
      <FormControl sx={{ mb: 4 }}>
        <RadioGroup className="vertical-align" row aria-labelledby="row-radio-buttons" name="row-radio-buttons-group" value={selectedTab}>
          <FormControlLabel value="cancelled" control={<Radio onChange={handleRadioChange} />} label="Анулирай" />
          <FormControlLabel value="update" control={<Radio onChange={handleRadioChange} />} label="Актуализирай" />
          <FormControlLabel value="reissued" control={<Radio onChange={handleRadioChange} />} label="Преиздай" />
        </RadioGroup>
      </FormControl>
      <Typography textAlign="flex-start" mb={1}>
        {title} {selectedTab === "update" && "анулиран"} стикер
      </Typography>
      <Box mb={3}>
        {/*нарочно са отделени cancelled и reissued заради дефолтните стойности на полетата*/}
        {selectedTab === "cancelled" && (
          <CancelStickerForm onSuccess={onSuccess} id={id} installmentNumber={installmentNumber} payment={payment} policy={policy} status={selectedTab} btnTxt={title} />
        )}
        {selectedTab === "reissued" && (
          <CancelStickerForm onSuccess={onSuccess} id={id} installmentNumber={installmentNumber} payment={payment} policy={policy} status={selectedTab} btnTxt={title} />
        )}
        {selectedTab === "update" && (
          <NewStickerForm onSuccess={onSuccess} id={id} installmentNumber={installmentNumber} payment={payment} policy={policy} status={selectedTab} btnTxt={title} />
        )}
      </Box>
      <Box>
        <Typography textAlign="flex-start" mb={1}>
          {title} {selectedTab === "update" && "анулирана"} зелена карта
        </Typography>
        {/*нарочно са отделени cancelled и reissued заради дефолтните стойности на полетата*/}
        {selectedTab === "cancelled" && (
          <CancelGreenCardForm onSuccess={onSuccess} id={id} installmentNumber={installmentNumber} payment={payment} policy={policy} status={selectedTab} btnTxt={title} />
        )}
        {selectedTab === "reissued" && (
          <CancelGreenCardForm onSuccess={onSuccess} id={id} installmentNumber={installmentNumber} payment={payment} policy={policy} status={selectedTab} btnTxt={title} />
        )}
        {selectedTab === "update" && (
          <NewGreenCardForm onSuccess={onSuccess} id={id} installmentNumber={installmentNumber} payment={payment} policy={policy} status={selectedTab} btnTxt={title} />
        )}
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" startIcon={<CloseIcon />} onClick={onClose}>
          Затвори
        </Button>
      </Box>
    </>
  );

  return (
    <Modal open={open} closeIcon toggle={toggle}>
      {content}
    </Modal>
  );
};

export default CancelPolicyStickerModal;
