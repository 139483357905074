import React from "react";
import { txtFields } from "../../../../config/StickerForm.component.config";
import withValidation from "../../../HOC/withValidation";
import { updateGODocument } from "../../../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../../../utils/notifications";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const NewStickerForm = props => {
  const { onSuccess, id, installmentNumber, formData, payment, policy, btnTxt } = props;
  async function handleCancelSticker(e) {
    if (props?.handleValidation()) {
      try {
        const response = await updateGODocument(id, installmentNumber, "sticker", formData["sticker"]); //todo
        if (response?.status === 200) {
          successAlert("updated successfully!");
          onSuccess(response.data);
        }
      } catch (e) {
        errorAlert(e?.response?.data || e?.message);
      }
    }
  }
  return (
    <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
      <Grid item xs={8}>
        {props?.renderFields(props, txtFields)}
      </Grid>
      <Grid item xs={4}>
        <Button variant="contained" startIcon={<AddIcon />} type="submit" color="success" onClick={handleCancelSticker} sx={{ mb: 1, mt: 1 }}>
          {btnTxt}
        </Button>
      </Grid>
    </Grid>
  );
};

export default withValidation(NewStickerForm, { txtFields });
