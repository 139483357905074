export const txtFields = [
  {
    name: "policyId",
    label: "Полица №*",
    placeholder: " 21001M30004",
    validators: ["required"],
    errorMessages: ["Полето е задължително"]
  },
  {
    name: "premium",
    label: "Внесена премия (без данък) *",
    placeholder: "9.80",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "tax",
    label: "Данък внесена премия *",
    placeholder: "0.20",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "total",
    label: "Внесена премия (с данък без отстъпка) *",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "totalPromo",
    label: "Внесена премия (след отстъпка)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountPremium", //"fullAmountPremium","fullAmountTax", "fullAmountTotal", "fullAmountPromo"
    label: "Годишна премия без данък",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountTax",
    label: "Данък в/у годишна премия",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountTotal",
    label: "Годишна премия",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountPromo",
    label: "Годишна премия (след отстъпка)",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "fullAmountCommission",
    label: "Комисионна",
    placeholder: "10.00",
    validators: ["required", "validateSum"],
    errorMessages: ["Полето е задължително", "Невалидна сума"]
  },
  {
    name: "name",
    label: "Име на застрахован/застраховащ *",
    placeholder: "Флай2скай ЕООД - Анна Христова Иванова ",
    validators: ["required", "validateName"],
    errorMessages: ["Полето е задължително", "Невалидно име"]
  },
  {
    name: "address",
    label: "Адрес",
    placeholder: "Младост 4"
  },
  {
    name: "city",
    label: "Населено място",
    placeholder: "София"
  },
  {
    name: "email",
    label: "Еmail",
    placeholder: "kristian@boleron.com",
    validators: ["required", "validateEmail"],
    errorMessages: ["Полето е задължително", "Невалиден email адрес"],
    value: "office@boleron.com"
  },
  {
    name: "phone",
    label: "Телефон",
    placeholder: "0884286747"
  },
  {
    name: "egnEik",
    label: "ЕГН / EИК *",
    validators: ["required", "validateEIKOrEgn"],
    errorMessages: ["Полето е задължително", "Невалидно ЕГН / ЕИК"]
  }
];
export const selectFields = [
  {
    name: "installments",
    label: "Брой вноски *",
    validators: ["required"],
    options: [
      { label: "една", value: 1 },
      { label: "две", value: 2 },
      { label: "четири", value: 4 },
      { label: "шест", value: 6 },
      { label: "дванайсет", value: 12 }
    ]
  },
  {
    name: "insuranceType",
    label: "Тип застраховка",
    value: "life-offline",
    options: [
      { label: "life-offline", value: "life-offline" },
      { label: "non-life-offline", value: "non-life-offline" }
    ]
  },
  {
    name: "subProduct",
    label: "Вид застраховка *",
    validators: ["required"],
    options: [
      { label: "Гражданска отговорност", value: "Гражданска отговорност" },
      { label: "Допълнително Здравно", value: "Допълнително Здравно" },
      { label: "Застраховка на вземания", value: "Застраховка на вземания" },
      { label: "Здраве и ценност", value: "Здраве и ценност" },
      { label: "Имущество", value: "Имущество" },
      { label: "Каско на въздухоплавателни средства (ВС)", value: "Каско на въздухоплавателни средства (ВС)" },
      { label: "Каско", value: "Каско" },
      { label: "Медицинска застраховка за чужденци ", value: "Медицинска застраховка за чужденци" },
      { label: "Отговорност", value: "Отговорност" },
      { label: "Отговорности свързани с експлоатацията на ВС", value: "Отговорности свързани с експлоатацията на ВС" },
      { label: "Плавателни съдове", value: "Плавателни съдове" },
      { label: "Планинска застраховка", value: "Планинска застраховка" },
      { label: "Пътуване в чужбина", value: "Пътуване в чужбина" },
      { label: "Трудова Злополука", value: "Трудова Злополука" },
      { label: "Best Doctors", value: "Best Doctors" }
    ]
  },
  {
    name: "insurerId",
    label: "Застраховател *",
    validators: ["required"],
    options: [
      { label: "Алианц", value: "allianz" },
      { label: "Армеец", value: "armeec" },
      { label: "Бул Инс", value: "bulins" },
      { label: "Булстрад Живот", value: "bulstradlife" },
      { label: "Булстрад", value: "bulstrad" },
      { label: "ДЗИ", value: "dzi" },
      { label: "Дал Бог", value: "dallbogg" },
      { label: "Дженерали", value: "generali" },
      { label: "Евроинс", value: "euroins" },
      { label: "Левинс", value: "levins" },
      { label: "ОЗК", value: "ozk" },
      { label: "Уника", value: "uniqa" },
      { label: "Coface", value: "coface" },
      { label: "Colonnade", value: "colonnade" }
    ]
  }
];
