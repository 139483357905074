import React from "react";
import { Box, Button, Icon, TextField, LinearProgress } from "@mui/material";
import Modal from "../Modal";
import { connect } from "react-redux";
import { danger, success, gray } from "../../utils/colors";

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [`${props.modalName}IsLoading`]: false
    };
  }
  handleLoading = (modalName, isLoading) => {
    this.setState({
      [`${modalName}IsLoading`]: isLoading
    });
  };
  render() {
    const { modalName, modalTitle, btnTxt, btnColor, btnDisabled, isModalOpen, toggle, objWithEgnAndRequestId, egn, handle, showEgnCheck, egnValidationError, onChange, children } =
      this.props;
    const props = { ...this.props, handleLoading: this.handleLoading };
    const btnDisabledFromParent = btnDisabled || false;
    const isLoading = this.state[`${this.props.modalName}IsLoading`];
    const disabled = !!((showEgnCheck && !egn) || btnDisabledFromParent || isLoading);
    const content = (
      <div>
        <Box mt={2} mb={2}>
          {isLoading && <LinearProgress />}
        </Box>
        <p>ЕГН/ЕИК: {objWithEgnAndRequestId.egn}</p>
        {objWithEgnAndRequestId?.requestId && <p>requestId: {objWithEgnAndRequestId.requestId}</p>}
        <br />
        <br />
        {children && <Box mb={2}>{children}</Box>}
        {showEgnCheck && (
          <Box mb={2}>
            <TextField fullWidth label="Код за потвърждение" variant="outlined" size="small" value={egn} onChange={e => onChange(e)} />
            {egnValidationError && <p style={{ color: danger }}>Невалиден код!</p>}
          </Box>
        )}
        <Box mb={2}>
          <Button
            onClick={() => handle(props)}
            disabled={disabled}
            variant="contained"
            size="small"
            style={{
              background: !disabled ? (btnColor ? btnColor : success) : gray,
              color: "#fff",
              width: "min-content",
              float: "right",
              cursor: "pointer"
            }}
            endIcon={<Icon>arrow_right</Icon>}
          >
            {btnTxt}
          </Button>
          <div style={{ clear: "both" }} />
        </Box>
      </div>
    );
    return (
      <Modal open={isModalOpen} toggle={() => toggle(modalName)} title={modalTitle} description="" closeIcon>
        {content}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(ConfirmationModal);
