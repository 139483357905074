import React, { useState } from "react";
import { Typography, Stack, Button, TextField, Box } from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Modal from "../Modal/Modal";
// import { createCSCOldPolicy } from "../../services/PoliciesService";
import { error as errorAlert, success as successAlert } from "../../utils/notifications";

const CreatePolicyModal = ({ isCreatePolicyModalOpen, toggle, objWithEgnAndRequestId }) => {
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [textFieldIsVisible, setTextFieldIsVisible] = useState(false);
  const [fieldAmount, setFieldAmount] = useState("");

  const addCSCPolicy = async () => {
    try {
      // const response = await createCSCOldPolicy(objWithEgnAndRequestId?.requestId);
      // if ([200, 201, 204].includes(response?.status)) {
      //   successAlert("added successfully!");
      // }
    } catch (e) {
      errorAlert(e.message);
    }
  };

  const handleButtonClick = () => {
    setButtonIsDisabled(true);
    setTextFieldIsVisible(true);
  };

  const handleTextFieldChange = e => {
    setFieldAmount(e?.target?.value);
    setButtonIsDisabled(false);
  };

  const handleSubmit = () => {
    addCSCPolicy();
  };

  const content = (
    <>
      <Typography>{`ЕГН/ЕИК: ${objWithEgnAndRequestId?.egn}`}</Typography>
      <Typography mt={2}>{`RequestId: ${objWithEgnAndRequestId?.requestId}`}</Typography>
      {textFieldIsVisible && (
        <Box mt={5}>
          <TextField fullWidth label="Код за потвърждение" variant="outlined" size="small" value={fieldAmount} onChange={handleTextFieldChange} />
        </Box>
      )}
      <Stack mt={2} direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          disabled={buttonIsDisabled || (textFieldIsVisible && !fieldAmount)}
          endIcon={<AddTaskIcon />}
          onClick={fieldAmount ? handleSubmit : handleButtonClick}
        >
          Издай
        </Button>
      </Stack>
    </>
  );
  return (
    <Modal open={isCreatePolicyModalOpen} toggle={() => toggle("createPolicyModal")} title="Издаване на полица" closeIcon>
      {content}
    </Modal>
  );
};

export default CreatePolicyModal;
