import React from "react";
import { error as errorAlert, success as successAlert } from "../../../../utils/notifications";
import { txtFields } from "../../../../config/GreenCardForm.component.config";
import { Button, Grid } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import withValidation from "../../../HOC/withValidation";
import { cancelOrReissueGODocument } from "../../../../services/PoliciesService";

const CancelGreenCardForm = props => {
  const { onSuccess, id, installmentNumber, formData, payment, policy, status, btnTxt } = props;
  async function handleCancelGreenCard() {
    if (props?.handleValidation()) {
      try {
        const response = await cancelOrReissueGODocument(id, installmentNumber, "greenCard", `${formData["prefix"]}${formData["greenCard"]}`, status);
        if (response?.status === 200) {
          successAlert("cancelled/reissued successfully!");
          onSuccess(response.data);
        }
      } catch (e) {
        errorAlert(e?.response?.data || e?.message);
      }
    }
  }
  return (
    <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
      <Grid item xs={8}>
        {props?.renderFields(props, txtFields)}
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          startIcon={<DeleteIcon />}
          type="submit"
          color={status === "cancelled" ? "error" : "warning"}
          onClick={handleCancelGreenCard}
          sx={{ mb: 1, mt: 1 }}
        >
          {btnTxt}
        </Button>
      </Grid>
    </Grid>
  );
};
export default withValidation(CancelGreenCardForm, { txtFields });
