import _get from "lodash.get";

export const txtFields = [
  {
    name: "sticker",
    label: "Въведи стикер",
    type: "text",
    placeholder: "Ex: 00012678",
    validators: ["required", "stickerIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден Стикер - трябва да е 8 или 9 цифри."],
    defaultValueFunction: props => {
      if (["update", "reissued"].includes(props?.status)) return "";
      const sticker =
        _get(props, "payment.paymentId") === "1"
          ? _get(props, "payment.otherData.sticker", "") || _get(props, "policy.policyData.sticker", "")
          : _get(props, "payment.otherData.sticker", "");
      return sticker;
    }
  }
];
