import React from "react";
import { gray, grayBorder, success, warning } from "../../../utils/colors";
import { Box, Button, Chip, Icon, Typography } from "@mui/material";

const AddDuePayments = props => {
  const { handleSubmit, handleChipClick, loading, paymentsCount } = props;
  return (
    <div className="edit-policy-add-duepayments-wrapper">
      <Typography fontSize={20} textAlign="center">
        Добави вноски:
      </Typography>
      <Box mb={2} mt={2}>
        {[1, 2, 4, 12].map(installment => (
          <Chip
            key={installment}
            onClick={() => handleChipClick(installment)}
            size="medium"
            label={<Typography fontSize={14}>{installment}</Typography>}
            style={{
              background: paymentsCount === installment ? warning : gray,
              color: "white",
              float: "none",
              marginRight: 5
            }}
          />
        ))}
      </Box>

      <Button
        onClick={handleSubmit}
        disabled={!paymentsCount || loading}
        type="submit"
        variant="contained"
        size="small"
        style={{
          background: paymentsCount && !loading ? success : gray
        }}
        endIcon={<Icon>arrow_right</Icon>}
      >
        Добави
      </Button>
    </div>
  );
};

export default AddDuePayments;
