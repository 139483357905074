import React, { createElement, useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import PoliciesTableNew from "../Policies/PoliciesTableNew";
import qs from "qs";
import _get from "lodash.get";
import routesConfig from "../../../routes/routesConfig";
import { capitalizeFirstLetter, getKeyByValue } from "../../../utils/helpers";
import withMultipleModals from "../../HOC/withMultipleModals";
import withParams from "../../HOC/withParams";
import _find from "lodash.find";
import * as AllActionModals from "../../ActionModals";
import InnerTabs from "../../InnerTabs";
import { insurerObj } from "../EditPolicy/EditPolicy";
import ActionsProvider from "../../ActionsProvider";

const OFF = props => {
  const insurerIdLookup = insurerObj;

  const productNameKeyLookup = {
    imushtestvo: "Имущество",
    kasko: "Каско",
    bestDoctors: "Best Doctors",
    patuvaneVChuzhbina: "Пътуване в чужбина",
    grazhdanskaOtgovornost: "Гражданска отговорност",
    otgovornost: "Отговорност",
    trudovaZlopoluka: "Трудова Злополука",
    dopalnitelnoZdravno: "Допълнително Здравно",
    plavatelniSudove: "Плавателни съдове",
    zastrahovkaNaVzemanija: "Застраховка на вземания",
    planinskaZastrahovka: "Планинска застраховка",
    medicinskaZastrahovkaZaChuzhdenci: "Медицинска застраховка за чужденци",
    zdraveITsennost: "Здраве и ценност",
    kaskoNaVazduhoplavatelniSredstva: "Каско на въздухоплавателни средства (ВС)",
    otgovornostiSvurzaniSEksploatatsiyataNaVS: "Отговорности свързани с експлоатацията на ВС"
  };

  const variantLookup = {
    process: "Предстоящи",
    informed: "Информирани",
    overdue: "Просрочени",
    issued: "Платени",
    cancelled: "Прекратени",
    partiallyPaid: "Отказано плащане",
    paidElsewhere: "Платени другаде",
    rejected: "Отказани"
  };

  const policiesConfig = {
    active: "Активни",
    expiring: "Изтичащи",
    informed: "Информирани",
    renewed: "Подновени",
    expired: "Изтекли",
    cancelled: "Прекратени",
    all: "Всички"
  };

  const subVariantsConfig = {
    policies: "policiesSelectedTab",
    installments: "installmentsSelectedTab"
  };

  //todo този код може да отиде в withMultipleModals HOK-a
  const modals = {};
  props.data.forEach(el => {
    modals[el.id] = AllActionModals[capitalizeFirstLetter(el.id)];
  });

  const [selectedTab, setSelectedTab] = useState(sessionStorage.getItem("selectedTab") || "policies");
  const [installmentsSelectedTab, setInstallmentsSelectedTab] = useState(sessionStorage.getItem("installmentsSelectedTab") || getKeyByValue(variantLookup, "Предстоящи"));
  const [policiesSelectedTab, setPoliciesSelectedTab] = useState(sessionStorage.getItem("policiesSelectedTab") || getKeyByValue(policiesConfig, "Активни"));
  const [policy, setPolicy] = useState({});
  const searchQuery = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "query", "");
  const tabsState = { selectedTab, installmentsSelectedTab };

  const handleInstallmentsChange = (e, selected) => {
    setInstallmentsSelectedTab(selected);
    sessionStorage.setItem("installmentsSelectedTab", selected);
  };
  const handlePoliciesChange = (e, selected) => {
    setPoliciesSelectedTab(selected);
    sessionStorage.setItem("policiesSelectedTab", selected);
  };
  const handleAction = (policy, option, refreshDataMethod) => {
    setPolicy(policy);
    if (option.hasModal) {
      props.open(`${option.id}Modal`);
      props.setRefreshMethod(refreshDataMethod);
      props.closeAllExceptOne(`${option.id}Modal`);
    } else if (!option.hasModal && option.id === "editPolicy") {
      //todo да се провери дали policy._id го има при installments
      props.navigate(`${routesConfig.editPolicy.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "sendDocuments") {
      if (selectedTab === "installments") {
        if (policy.deliveryName) {
          return props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${policy.paymentId}/${policy.productId}/${policy.email}/${policy.deliveryName}`);
        } else {
          return props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${policy.paymentId}/${policy.productId}/${policy.email}/${policy.name}`);
        }
      } else {
        props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/1/${policy.productId}/${policy.email}/${policy.name}`);
      }
    }
  };

  const innerTabsPolicies = (
    <InnerTabs
      selected={policiesSelectedTab}
      handleChange={handlePoliciesChange}
      options={[
        { label: "Активни", value: "active" },
        { label: "Изтичащи", value: "expiring" },
        { label: "Информирани", value: "informed" },
        { label: "Подновени", value: "renewed" },
        { label: "Изтекли", value: "expired" },
        { label: "Прекратени", value: "cancelled" },
        { label: "Всички", value: "all" }
      ]}
    />
  );
  const innerTabsInstallments = (
    <InnerTabs
      selected={installmentsSelectedTab}
      handleChange={handleInstallmentsChange}
      options={[
        { label: "Предстоящи", value: getKeyByValue(variantLookup, "Предстоящи") },
        { label: "Просрочени", value: getKeyByValue(variantLookup, "Просрочени") },
        { label: "Информирани", value: getKeyByValue(variantLookup, "Информирани") },
        { label: "Платени", value: getKeyByValue(variantLookup, "Платени") },
        { label: "Всички", value: "all" }
      ]}
    />
  );

  useEffect(() => {}, [props.dataStatuses]);
  return (
    <PageLayout title="Фирмено застраховане" hasGrayBg {...props}>
      <ActionsProvider>
        {Object.keys(modals).map(k => {
          let modal = modals[k];
          return createElement(modal, {
            key: k,
            [`is${capitalizeFirstLetter(k)}Open`]: _get(_find(props.dataStatuses, { id: k }), "opened"),
            toggle: props.toggle,
            close: props.close,
            refreshTable: props?.refreshData,
            policy: ["refundModal", "incorrectPayersModal"].includes(k) ? policy : undefined,
            //todo тук могат да отпаднат policyDBId и paymentId като се преработи PayInstallmentModal модала
            objWithEgnAndRequestId: !["refundModal", "incorrectPayersModal"].includes(k)
              ? { ...policy, policyDBId: _get(policy, "_id"), paymentId: selectedTab === "installments" ? _get(policy, "paymentId")?.toString() : "1" }
              : undefined,
            variant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "changeStatusModal", "refundModal"].includes(k) ? selectedTab : undefined,
            subVariant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "changeStatusModal", "refundModal"].includes(k)
              ? tabsState[subVariantsConfig[selectedTab]]
              : undefined
          });
        })}

        {selectedTab === "policies" && (
          <>
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Активни") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                primacy="policyPrimacy"
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна"
                }}
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                statusDefaultFilter={["active", "renewal"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-60"
                toDays="15"
                listOfProps={[
                  "productId",
                  "productNameKey",
                  "userId",
                  "status",
                  "offer",
                  "insurerId",
                  "name",
                  "offerDate",
                  "period",
                  "sumTotal",
                  "numberOfInstallments",
                  "depositedTotal"
                ]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Изтичащи") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                primacy="policyPrimacy"
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  expired: "изтекла"
                }}
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                statusDefaultFilter={["expiring"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "productNameKey", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}

            {policiesSelectedTab === getKeyByValue(policiesConfig, "Информирани") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                primacy="policyPrimacy"
                productDefaultFilter={["OFF"]}
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                statusDefaultFilter={["informed"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "productNameKey", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}

            {policiesSelectedTab === getKeyByValue(policiesConfig, "Подновени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                primacy="policyPrimacy"
                productDefaultFilter={["OFF"]}
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                statusDefaultFilter={["renewed"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "productNameKey", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}

            {policiesSelectedTab === getKeyByValue(policiesConfig, "Изтекли") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                primacy="policyPrimacy"
                productDefaultFilter={["OFF"]}
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                statusLookup={{
                  expired: "изтекла"
                }}
                statusDefaultFilter={["expiring", "active"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="5"
                listOfProps={["productId", "productNameKey", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}

            {policiesSelectedTab === getKeyByValue(policiesConfig, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="policyPrimacy"
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "productNameKey", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Всички") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="policyPrimacy"
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expiring", "cancelled", "renewed", "renewal"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "productNameKey", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
          </>
        )}

        {selectedTab === "installments" && (
          <>
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Предстоящи") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="duepaymentPrimacy"
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["process"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="15"
                productId
                listOfProps={["productId", "productNameKey", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}

            {installmentsSelectedTab === getKeyByValue(variantLookup, "Просрочени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="duepaymentPrimacy"
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["overdue"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-30"
                toDays="30"
                productId
                listOfProps={["productId", "productNameKey", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Информирани") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="duepaymentPrimacy"
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["informed"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "productNameKey", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}

            {installmentsSelectedTab === getKeyByValue(variantLookup, "Платени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["issued"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-5"
                toDays="5"
                productId
                listOfProps={["productId", "productNameKey", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="duepaymentPrimacy"
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["cancelled", "partiallyPaid"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "productNameKey", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}

            {installmentsSelectedTab === getKeyByValue(variantLookup, "Платени другаде") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="duepaymentPrimacy"
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["paidElsewhere"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "productNameKey", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === "all" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  HLT: "Здраве и спокойствие"
                }}
                primacy="duepaymentPrimacy"
                productNameKeyDefaultFilter={[]}
                productNameKeyLookup={productNameKeyLookup}
                productDefaultFilter={["OFF"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "productNameKey", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
          </>
        )}
      </ActionsProvider>
    </PageLayout>
  );
};
export default withMultipleModals(withParams(OFF), [
  { id: "refundModal" },
  { id: "cancelPolicyModal" },
  { id: "changeStatusModal" },
  { id: "checkGOStatusModal" },
  { id: "changeStatusPolicyModal" },
  { id: "createPolicyModal" },
  { id: "incorrectPayersModal" },
  { id: "riskyUserProfileModal" },
  { id: "payInstallmentModal" },
  { id: "updateDateOfDuePaymentModal" },
  { id: "issuePolicyWizardModal" }
]);
