import _get from "lodash.get";

export const txtFields = [
  {
    name: "prefix",
    label: "Префикс на Зелена карта",
    type: "text",
    placeholder: "Ex. BG/XY...",
    width: "200px",
    validators: ["required", "prefixIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден Префикс на Зелена карта- трябва да има 2 ГЛАВНИ букви."],
    defaultValueFunction: props => {
      if (["update", "reissued"].includes(props?.status)) return "";
      const greenCardPrefix =
        _get(props, "payment.paymentId") === "1"
          ? _get(props, "payment.otherData.greenCardPrefix", "") || _get(props, "policy.policyData.greenCardPrefix", "")
          : _get(props, "payment.otherData.greenCardPrefix", "");
      return greenCardPrefix;
    }
  },
  {
    name: "greenCard",
    label: "Номер на Зелена карта",
    type: "text",
    placeholder: "Ex. 001278",
    width: "300px",
    validators: ["required", "greenCardIsValid"],
    errorMessages: ["Полето е задължително", "Невалиден номер на Зелена карта - трябва да е 6 цифри."],
    defaultValueFunction: props => {
      if (["update", "reissued"].includes(props?.status)) return "";
      const greenCard =
        _get(props, "payment.paymentId") === "1"
          ? _get(props, "payment.otherData.greenCard", "") || _get(props, "policy.policyData.greenCard", "")
          : _get(props, "payment.otherData.greenCard", "");
      return greenCard;
    }
  }
];
