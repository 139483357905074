import React, { createElement, useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import PoliciesTableNew from "../Policies/PoliciesTableNew";
import qs from "qs";
import _get from "lodash.get";
import routesConfig from "../../../routes/routesConfig";
import { capitalizeFirstLetter, getKeyByValue } from "../../../utils/helpers";
import withMultipleModals from "../../HOC/withMultipleModals";
import withParams from "../../HOC/withParams";
import _find from "lodash.find";
import * as AllActionModals from "../../ActionModals";
import InnerTabs from "../../InnerTabs";
import ActionsProvider from "../../ActionsProvider";

const PRP = props => {
  const insurerIdLookup = {
    bulstrad: "Булстрад",
    uniqa: "Уника"
  };

  const variantLookup = {
    process: "Предстоящи",
    informed: "Информирани",
    overdue: "Просрочени",
    issued: "Издадени",
    confirmed: "Потвърдени",
    cancelled: "Прекратени",
    partiallyPaid: "Отказано плащане",
    paidElsewhere: "Платени другаде",
    rejected: "Отказани"
  };

  const policiesConfig = {
    active: "Активни",
    expiring: "Изтичащи",
    informed: "Информирани",
    renewed: "Подновени",
    expired: "Изтекли",
    cancelled: "Прекратени",
    all: "Всички"
  };

  const subVariantsConfig = {
    policies: "policiesSelectedTab",
    installments: "installmentsSelectedTab"
  };

  //todo този код може да отиде в withMultipleModals HOK-a
  const modals = {};
  props.data.forEach(el => {
    modals[el.id] = AllActionModals[capitalizeFirstLetter(el.id)];
  });

  const [selectedTab, setSelectedTab] = useState(sessionStorage.getItem("selectedTab") || "policies");
  const [installmentsSelectedTab, setInstallmentsSelectedTab] = useState(sessionStorage.getItem("installmentsSelectedTab") || getKeyByValue(variantLookup, "Предстоящи"));
  const [policiesSelectedTab, setPoliciesSelectedTab] = useState(sessionStorage.getItem("policiesSelectedTab") || getKeyByValue(policiesConfig, "Активни"));
  const [policy, setPolicy] = useState({});
  const searchQuery = _get(qs.parse(props?.location?.search, { ignoreQueryPrefix: true }), "query", "");
  const tabsState = { selectedTab, installmentsSelectedTab, policiesSelectedTab };

  const handleInstallmentsChange = (e, selected) => {
    setInstallmentsSelectedTab(selected);
    sessionStorage.setItem("installmentsSelectedTab", selected);
  };
  const handlePoliciesChange = (e, selected) => {
    setPoliciesSelectedTab(selected);
    sessionStorage.setItem("policiesSelectedTab", selected);
  };
  const handleAction = (policy, option, refreshDataMethod) => {
    setPolicy(policy);
    if (option.hasModal) {
      props.open(`${option.id}Modal`);
      props.setRefreshMethod(refreshDataMethod);
      props.closeAllExceptOne(`${option.id}Modal`);
    } else if (!option.hasModal && option.id === "sendOffer") {
      props.navigate(`${routesConfig.sendOffer.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "editPolicy") {
      //todo да се провери дали policy._id го има при installments
      props.navigate(`${routesConfig.editPolicy.route}/${policy._id}`);
    } else if (!option.hasModal && option.id === "sendDocuments") {
      if (selectedTab === "installments") {
        if (policy.deliveryName) {
          return props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${policy.paymentId}/${policy.productId}/${policy.email}/${policy.deliveryName}`);
        } else {
          return props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${policy.paymentId}/${policy.productId}/${policy.email}/${policy.name}`);
        }
      } else {
        if (policy.policyData.deliveryName) {
          let goInstallmentNo = policy.policyData.goInstallmentNo;
          if (goInstallmentNo) {
            goInstallmentNo = "" + goInstallmentNo;
            props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/${goInstallmentNo}/${policy.productId}/${policy.email}/${policy.policyData.deliveryName}`);
          } else {
            props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/1/${policy.productId}/${policy.email}/${policy.policyData.deliveryName}`);
          }
        } else {
          props.navigate(`${routesConfig.sendDocuments.route}/${policy._id}/1/${policy.productId}/${policy.email}/${policy.name}`);
        }
      }
    } else if (!option.hasModal && option.id === "shipment") {
      if (selectedTab === "installments") {
        props.navigate(`${routesConfig.shipment.route}/${policy._id}`, { state: JSON.stringify(policy) }); // state-a в ReactRouter се очаква да е serializable, ako ne e праща null
      } else {
        props.navigate(`${routesConfig.shipment.route}/${policy._id}`);
      }
    }
  };

  const innerTabsPolicies = (
    <InnerTabs
      selected={policiesSelectedTab}
      handleChange={handlePoliciesChange}
      options={[
        { label: "Активни", value: "active" },
        { label: "Изтичащи", value: "expiring" },
        { label: "Информирани", value: "informed" },
        { label: "Подновени", value: "renewed" },
        { label: "Изтекли", value: "expired" },
        { label: "Прекратени", value: "cancelled" },
        { label: "Всички", value: "all" }
      ]}
    />
  );
  const innerTabsInstallments = (
    <InnerTabs
      selected={installmentsSelectedTab}
      handleChange={handleInstallmentsChange}
      options={[
        { label: "Предстоящи", value: getKeyByValue(variantLookup, "Предстоящи") },
        { label: "Просрочени", value: getKeyByValue(variantLookup, "Просрочени") },
        { label: "Информирани", value: getKeyByValue(variantLookup, "Информирани") },
        { label: "Потвърдени", value: getKeyByValue(variantLookup, "Потвърдени") },
        { label: "Издадени", value: getKeyByValue(variantLookup, "Издадени") },
        { label: "Платени другаде", value: getKeyByValue(variantLookup, "Платени другаде") },
        { label: "Всички", value: "all" }
      ]}
    />
  );

  useEffect(() => {}, [props.dataStatuses]);
  return (
    <PageLayout title="Имущество" hasGrayBg {...props}>
      <ActionsProvider>
        {Object.keys(modals).map(k => {
          let modal = modals[k];
          return createElement(modal, {
            key: k,
            [`is${capitalizeFirstLetter(k)}Open`]: _get(_find(props.dataStatuses, { id: k }), "opened"),
            toggle: props.toggle,
            close: props.close,
            refreshTable: props?.refreshData,
            policy: ["refundModal", "incorrectPayersModal"].includes(k) ? policy : undefined,
            //todo тук могат да отпаднат policyDBId и paymentId като се преработи PayInstallmentModal модала
            objWithEgnAndRequestId: !["refundModal", "incorrectPayersModal"].includes(k)
              ? { ...policy, policyDBId: policy["_id"], paymentId: selectedTab === "installments" ? _get(policy, "paymentId")?.toString() : "1" }
              : undefined,
            variant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "changeStatusModal", "refundModal"].includes(k) ? selectedTab : undefined,
            subVariant: ["changeStatusPolicyModal", "issuePolicyWizardModal", "changeStatusModal", "refundModal"].includes(k)
              ? tabsState[subVariantsConfig[selectedTab]]
              : undefined
          });
        })}

        {selectedTab === "policies" && (
          <>
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Активни") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна"
                }}
                statusDefaultFilter={["active", "renewal"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Изтичащи") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  expired: "изтекла"
                }}
                statusDefaultFilter={["expiring"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}

            {policiesSelectedTab === getKeyByValue(policiesConfig, "Информирани") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["PRP"]}
                statusDefaultFilter={["informed"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}

            {policiesSelectedTab === getKeyByValue(policiesConfig, "Подновени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["PRP"]}
                statusDefaultFilter={["renewed"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="0"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}

            {policiesSelectedTab === getKeyByValue(policiesConfig, "Изтекли") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  expired: "изтекла"
                }}
                statusDefaultFilter={["expired", "active"]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="5"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}

            {policiesSelectedTab === getKeyByValue(policiesConfig, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
            {policiesSelectedTab === getKeyByValue(policiesConfig, "Всички") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={policiesSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="policyPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "pending", "expiring", "cancelled", "renewed", "informed", "renewal"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                listOfProps={["productId", "status", "offer", "insurerId", "name", "offerDate", "period", "sumTotal", "numberOfInstallments", "depositedTotal"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsPolicies}
              />
            )}
          </>
        )}

        {selectedTab === "installments" && (
          <>
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Предстоящи") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["process"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="15"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}

            {installmentsSelectedTab === getKeyByValue(variantLookup, "Просрочени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["overdue"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-30"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Информирани") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["informed"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}

            {installmentsSelectedTab === getKeyByValue(variantLookup, "Потвърдени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["confirmed"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-5"
                toDays="5"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}

            {installmentsSelectedTab === getKeyByValue(variantLookup, "Издадени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  PRP: "Имущество"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["issued"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-5"
                toDays="5"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === getKeyByValue(variantLookup, "Прекратени") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  GO: "Гражданска"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["GO"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["cancelled", "partiallyPaid"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}

            {installmentsSelectedTab === getKeyByValue(variantLookup, "Платени другаде") && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  GO: "Гражданска"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={["paidElsewhere"]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
            {installmentsSelectedTab === "all" && (
              <PoliciesTableNew
                title=""
                variant={selectedTab}
                subVariant={installmentsSelectedTab}
                productLookup={{
                  GO: "Гражданска"
                }}
                primacy="duepaymentPrimacy"
                productDefaultFilter={["PRP"]}
                statusLookup={{
                  active: "активна",
                  pending: "обработва се",
                  expired: "изтекла",
                  cancelled: "прекратена"
                }}
                statusDefaultFilter={["active", "expired", "cancelled"]}
                offerDefaultFilter={[]}
                insurerIdLookup={insurerIdLookup}
                insurerIdDefaultFilter={[]}
                variantLookup={variantLookup}
                variantDefaultFilter={[]}
                handleAction={handleAction}
                searchQuery={searchQuery}
                fromDays="-15"
                toDays="30"
                productId
                listOfProps={["productId", "offer", "name", "variant", "insurerId", "contractDueDate", "paymentId", "dueDate", "period", "amountDue"]}
                setSelectedTab={setSelectedTab}
                innerTabs={innerTabsInstallments}
              />
            )}
          </>
        )}
      </ActionsProvider>
    </PageLayout>
  );
};
export default withMultipleModals(withParams(PRP), [
  { id: "refundModal" },
  { id: "cancelPolicyModal" },
  { id: "changeStatusModal" },
  { id: "checkGOStatusModal" },
  { id: "changeStatusPolicyModal" },
  { id: "createPolicyModal" },
  { id: "incorrectPayersModal" },
  { id: "riskyUserProfileModal" },
  { id: "payInstallmentModal" },
  { id: "updateDateOfDuePaymentModal" },
  { id: "issuePolicyWizardModal" }
]);
