import React from "react";
import _get from "lodash.get";
import { checkGOStatus, getEuroinsPolicyPdf } from "../../services/PoliciesService";
import withSimpleModal from "../HOC/withSimpleModal";
import ResultModal from "../Modals/ResultModal";
import { Grid, Icon } from "@mui/material";
import { primary, success, danger } from "../../utils/colors";
import moment from "moment";

class DownloadPolicyPdfModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { modalName, isDownloadPolicyPdfModalOpen, toggle, objWithEgnAndRequestId, handle, responseData } = this.props;
    const modalTitle = "Свали PDF от Евроинс";

    return (
      <ResultModal
        modalName={modalName}
        modalTitle={modalTitle}
        noBtn
        shouldHandle={!responseData}
        isModalOpen={isDownloadPolicyPdfModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={objWithEgnAndRequestId}
        handle={handle}
      >
        {responseData ? (
          <div className="horizontally-centered vertical-align">
            {responseData.includes("http") ? (
              <a href={responseData} className="document-link" target="_blank" rel="noopener noreferrer">
                кликни тук, за да свалиш pdf-а
              </a>
            ) : (
              <p>{responseData}</p>
            )}
          </div>
        ) : null}
      </ResultModal>
    );
  }
}

const generatePayloadRequest = props => {
  const requestPayload = {
    requestId: _get(props, "objWithEgnAndRequestId.requestId")
  };
  return requestPayload;
};

export default withSimpleModal(DownloadPolicyPdfModal, {
  generatePayloadRequest,
  apiRequest: getEuroinsPolicyPdf,
  modalName: "downloadPolicyPdfModal",
  closeAfterRequest: false,
  refreshAfterRequest: false
});
